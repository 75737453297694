<template>
    <div class="infoMain">
        <alert-info
            :info="{ zysx: '加“*”是必填项，请按规定填写！' }"
        ></alert-info>
        <div class="userinfo scrollbar userinfomain">
            <el-form
                ref="userbasic"
                :model="userbasic"
                :rules="rules"
                label-width="100px"
                class="demo-ruleForm"
            >
                <p class="header">基本信息</p>
                <el-form-item label="姓名" prop="REALNAME">
                    <el-input
                        v-model="userbasic.REALNAME"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="NAME">
                    <el-input
                        v-model="userbasic.NAME"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="SEX">
                    <el-radio v-model="userbasic.SEX" class="radio" :label="1"
                        >男</el-radio
                    >
                    <el-radio v-model="userbasic.SEX" class="radio" :label="2"
                        >女</el-radio
                    >
                </el-form-item>
                <el-form-item
                    v-if="strId === 'wu'"
                    label="密码"
                    prop="PASSWORD"
                >
                    <el-input
                        v-model="userbasic.PASSWORD"
                        type="password"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    v-if="strId === 'wu'"
                    label="确认密码"
                    prop="PASSWORDOK"
                >
                    <el-input
                        v-model="userbasic.PASSWORDOK"
                        type="password"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="MOBILE">
                    <el-input
                        v-model="userbasic.MOBILE"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="EMAIL">
                    <el-input
                        v-model="userbasic.EMAIL"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <el-form-item label="部门" prop="departmentID" class="tree-select-form-item">
                    <SnTreeselect
                        :treeselectData="orgData"
                        :treeselectValue="userbasic.DEPARTMENTID"
                        @handleChange="orgChange"
                        @openVueTree="openVueTree"
                    ></SnTreeselect>
                </el-form-item>
                <el-form-item label="备注" prop="REMARK">
                    <el-input
                        style="margin-top: 10px"
                        v-model="userbasic.REMARK"
                        type="textarea"
                        :autosize="{ minRows: 3 }"
                        :maxlength="200"
                        onkeydown="if(event.keyCode==32) return false"
                    ></el-input>
                </el-form-item>
                <p class="header">
                    web端权限<i v-if="showError" class="msg"
                        >新建web用户时此项必填</i
                    >
                </p>
                <el-form-item label="角色" prop="ROLEID">
                    <el-select
                        v-model="userbasic.ROLEID"
                        clearable
                        filterable
                        multiple
                        placeholder="请选择角色"
                        ref="select"
                        class="role-select"
                    >
                        <el-option
                            v-for="item in rolelist"
                            :key="item.ID"
                            :label="item.NAME"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <p class="header">
                    APP权限<i v-if="showError" class="msg"
                        >新建APP用户时此项必填</i
                    >
                </p>
                <el-form-item label="" prop="FUNC" label-width="40px">
                    <el-tree
                        :data="AppList"
                        :indent="30"
                        :default-expand-all="true"
                        :expand-on-click-node="false"
                        :props="defaultProps"
                    >
                        <span class="custom-tree-node" slot-scope="{ data }">
                            <el-switch
                                :disabled="data.CHILD && data.CHILD.length > 0"
                                v-model="data.isChecked"
                                class="blueBg"
                                :active-text="data.NAME"
                                @change="
                                    (val) => {
                                        changeSwitch(val, data);
                                    }
                                "
                            ></el-switch>
                        </span>
                    </el-tree>
                </el-form-item>
                <el-form-item class="buttonItem">
                    <sn-button
                        size="small"
                        :snButton="saveBtn"
                        @handleChange="save"
                        >保存</sn-button
                    >
                    <router-link :to="{ name: 'user' }">
                        <sn-button :snButton="cancleBtn" size="small"
                            >取消</sn-button
                        >
                    </router-link>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import alertInfo from "@comp/alert_info";
import { mapActions } from "vuex";
export default {
    name: "userinfo",
    components: {
        alertInfo,
    },
    data() {
        // 输入姓名 REALNAME
        let validateREALNAME = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入真实姓名"));
            } else {
                callback();
            }
        };
        // 输入用户名 NAME
        let validateNAME = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入用户名"));
            } else {
                if (value !== this.nameold) {
                    this.checkname(value).then((val) => {
                        if (!val) {
                            callback(new Error("用户名已存在"));
                        } else {
                            callback();
                        }
                    });
                } else {
                    callback();
                }
            }
        };
        // 输入密码 PASSWORD
        let validatePass = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入密码"));
            } else {
                if (this.userbasic.PASSWORDOK !== "") {
                    this.$refs.userbasic.validateField("PASSWORDOK");
                }
                callback();
            }
        };
        // 再次输入密码 PASSWORDOK
        let validatePass2 = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.userbasic.PASSWORD) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        // 输入联系方式 PASSWORDOK
        let validateTel = (rule, value, callback) => {
            if (!value) {
                callback(new Error("请输入联系电话"));
            } else if (value !== this.userbasic.PASSWORD) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            defaultProps: {
                children: "CHILD",
                label: "NAME",
            },
            rules: {
                REALNAME: [
                    {
                        required: true,
                        validator: validateREALNAME,
                        trigger: "blur",
                    },
                ],
                NAME: [
                    {
                        required: true,
                        validator: validateNAME,
                        trigger: "blur",
                    },
                ],
                DEPARTMENTID: [
                    {
                        required: true,
                        message: "请选择部门",
                        trigger: "change",
                    },
                ],
                // ROLEID: [
                //     { required: false, message: '请选择角色', trigger: 'change' }
                // ],
                // FUNC: [
                //     { required: true, message: '请选择角色', trigger: 'change' }
                // ],
                PASSWORD: [
                    {
                        required: true,
                        validator: validatePass,
                        trigger: "blur",
                    },
                ],
                PASSWORDOK: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
                MOBILE: [
                    {
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur",
                    },
                ],
            },
            saveBtn: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "save",
                        name: "保存",
                        round: true,
                        backColor: "#28ccd9",
                        disabled: false,
                    },
                ],
            },
            cancleBtn: {
                buttonData: [
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "",
                        name: "取消",
                        round: true,
                        backColor: "#ff9066",
                    },
                ],
            },
            strId: null,
            newdoc: "xinjian",
            nameold: "",
            userbasic: {
                // 用户基本信息
                PASSWORD: "",
                PASSWORDOK: "",
                DEPARTMENTID: "",
            },
            rolelist: [],
            AppList: [],
            AppListFlat: [], // app权限的扁平化
            showError: false,
            setDataCode: 0,
            timer: null,
            orgData: {
                isShow: true,
                selectValue: "",
                clearable: true,
                searchable: true,
                disableBranchNodes: false,
                placeholder: "请选择机构",
                width: "200px",
                list: [],
            }
        };
    },
    watch: {
        'userbasic.DEPARTMENTID': function () {
            this.getrole();
        }
    },
    mounted() {
        this.newdoc = this.$route.params.newdoc;
        this.strId = this.$route.params.strId;
        this.getorg();
        this.getrole();
        this.getAPPList();
        if (this.newdoc === "bianji") {
            this.getUserInfo();
        }
    },
    methods: {
        ...mapActions([
            "getUserData",
            "getDepNameList",
            "getRoleNameList",
            "checkUserName",
            "getMobleFunListEx",
            "saveUserData",
            "updateUserData",
        ]),
        changephone() {
            this.$nextTick(() => {
                this.userbasic.MOBILE = this.userbasic.MOBILE.replace(
                    /[^0-9]+/,
                    ""
                );
            });
        },
        async checkname(name) {
            let res = await this.checkUserName({
                name: name,
            });
            return res;
        },
        // 获得用户详细信息
        async getUserInfo() {
            let _this = this;
            let res = await this.getUserData({
                userid: this.strId,
            });
            this.userbasic = res;
            this.nameold = res.NAME;
            this.userbasic.ROLEID = (res.ROLEID && res.ROLEID.split(",")) || [];
            this.orgData.selectValue = this.userbasic.DEPARTMENTID;
            let data = (res.FUNCID && res.FUNCID.split(",")) || [];
            setData();
            function setData() {
                if (_this.AppList && _this.AppList.length > 0) {
                    setSwitch(_this.AppList);
                } else {
                    _this.timer && clearTimeout();
                    if (_this.setDataCode < 10) {
                        _this.timer = setTimeout(() => {
                            setData();
                            _this.setDataCode++;
                        }, 100);
                    }
                }
            }
            function setSwitch(da) {
                da.forEach((item) => {
                    if (item.CHILD && item.CHILD.length > 0) {
                        setSwitch(item.CHILD);
                    }
                    let isChecked =
                        (item.CHILD &&
                            item.CHILD.some((item) => item.isChecked)) ||
                        data.includes(item.ID);
                    _this.$set(item, "isChecked", isChecked);
                });
            }
            this.$forceUpdate();
        },
        // 获得机构列表
        async getorg() {
            let res = await this.getDepNameList();
            this.orgData.list = res;
        },
        // 获得角色列表
        async getrole() {
            let res = await this.getRoleNameList({
                depid: this.userbasic.DEPARTMENTID
            });
            this.rolelist = res || [];
            let allIDs = this.rolelist.map(item => item.ID);
            let arr = this.userbasic.ROLEID.filter(item => allIDs.indexOf(item) > -1);
            this.userbasic.ROLEID = arr;
        },
        // 获得APP权限列表
        async getAPPList() {
            let res = await this.getMobleFunListEx();
            this.AppList = res;
            this.AppListFlat = flatten(res, "");
            function flatten(arr, PID) {
                let res = [];
                arr.map((item) => {
                    if (item.CHILD && item.CHILD.length > 0) {
                        res = res.concat(flatten(item.CHILD, item.ID));
                    }
                    item.PID = PID;
                    res.push(item);
                });
                return res;
            }
        },
        orgChange(type, value) {
            this.userbasic.DEPARTMENTID = value[0];
        },
        openVueTree() {
            this.$refs.select.blur();
        },
        changeSwitch(isChecked, item) {
            let PID = item.PID;
            if (PID) {
                let index = this.AppListFlat.findIndex(
                    (item) => item.ID == PID
                );
                let checked = this.AppListFlat.some(
                    (item) => item.PID == PID && item.isChecked
                );
                this.$set(
                    this.AppListFlat[index],
                    "isChecked",
                    isChecked || checked
                );
            }
            this.$forceUpdate();
        },
        save() {
            let _this = this;
            let arr = this.AppListFlat.filter((item) => item.isChecked);
            let funids = arr.map((item) => item.ID);
            this.$refs.userbasic.validate((valid) => {
                if (_this.userbasic.ROLEID.length == 0 && funids.length === 0) {
                    _this.showError = true;
                    return false;
                }
                if (valid) {
                    let msg = "此操作将添加到数据库, 是否继续?";
                    _this
                        .$confirm(msg, "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "warning",
                            beforeClose: (action, instance, done) => {
                                if (action === "confirm") {
                                    this.saveBtn.buttonData[0].disabled = true;
                                    let fwname = "";
                                    let fwparams = {};
                                    if (_this.newdoc === "xinjian") {
                                        // 新建用户服务提交
                                        _this.userbasic.PASSWORD = MD5(
                                            _this.userbasic.PASSWORD
                                        ).toLocaleUpperCase();
                                        _this.userbasic.PASSWORDOK = MD5(
                                            _this.userbasic.PASSWORDOK
                                        ).toLocaleUpperCase();
                                        fwname = "saveUserData";
                                    }
                                    if (_this.newdoc === "bianji") {
                                        // 新建用户服务提交
                                        fwname = "updateUserData";
                                    }
                                    if (
                                        _this.userbasic.ROLEID.length > 0 &&
                                        funids.length > 0
                                    ) {
                                        _this.userbasic.USERTYPE = 3;
                                    } else if (
                                        _this.userbasic.ROLEID.length > 0 &&
                                        funids.length === 0
                                    ) {
                                        _this.userbasic.USERTYPE = 1;
                                    } else if (
                                        _this.userbasic.ROLEID.length == 0 &&
                                        funids.length > 0
                                    ) {
                                        _this.userbasic.USERTYPE = 2;
                                    }
                                    let info = JSON.stringify(_this.userbasic);
                                    fwparams = {
                                        info: info,
                                        roleids: this.userbasic.ROLEID.join(),
                                        funids: funids.join(),
                                    };
                                    _this.savePeople(fwname, fwparams, done);
                                } else {
                                    done();
                                }
                            },
                        })
                        .then(() => {})
                        .catch(() => {
                            _this.$message({
                                message: "已取消保存",
                                type: "info",
                            });
                        });
                } else {
                    return false;
                }
            });
        },
        async savePeople(fwname, fwparams, done) {
            let res = await this[fwname](fwparams);
            if (res.IsSuccess) {
                done();
                this.$message({ message: "保存成功", type: "success" });
                this.$router.push({
                    name: "user",
                });
            } else {
                this.$message({ message: "保存失败", type: "error" });
            }
        },
    },
};
</script>
<style lang="scss">
.userinfomain {
    .el-tree {
        background: transparent;
        > .el-tree-node {
            margin-bottom: 15px;
        }
        .el-tree-node__expand-icon {
            display: none;
        }
        .el-tree-node__children {
            display: flex;
            flex-wrap: wrap;
        }
    }
    .tree-select-form-item .vue-treeselect {
        width: 100%!important;
        margin-top: 5px;
        .vue-treeselect__input-container {
            display: flex;
            align-items: center;
        }
    }
    .role-select {
        width: 100%;
    }
}
</style>
<style lang="scss" scoped>
.header {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    color: #606266;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    .msg {
        font-size: 12px;
        color: #f56c6c;
        font-weight: 400;
        padding-left: 10px;
    }
}
.blueBg {
    margin-right: 30px;
}
</style>